import firebase from 'firebase/app'
import 'firebase/firestore'
import { reactive, toRefs, readonly } from 'vue'

const db = firebase.firestore()

const state = reactive({
  currentNotifications: [],
  nextNotifications: [],
  previousNotifications: []
})

export default function() {
  const resetStates = () => {
    state.currentNotifications = []
    state.nextNotifications = []
    state.previousNotifications = []
  }

  const fetchCurrentNotifications = () => {
    if (!state.currentNotifications.length)
      db.collection('NOTIFICATIONS')
        .where('end', '>=', new Date())
        .orderBy('end', 'desc')
        .get()
        .then(querySnap => {
          state.currentNotifications = querySnap.docs
            .map(doc => {
              const data = doc.data()
              const start = data.start.toMillis()
              const end = data.end.toMillis()
              if (new Date() > new Date(start)) return { ...data, id: doc.id, start, end }
            })
            .filter(n => n)
        })
  }

  const fetchPreviousNotifications = () => {
    if (!state.previousNotifications.length)
      db.collection('NOTIFICATIONS')
        .where('start', '<=', new Date())
        .orderBy('start', 'desc')
        .get()
        .then(querySnap => {
          state.previousNotifications = querySnap.docs
            .map(doc => {
              const data = doc.data()
              const start = data.start.toMillis()
              const end = data.end.toMillis()
              if (new Date() > new Date(end)) return { ...data, id: doc.id, start, end }
            })
            .filter(n => n)
        })
  }

  const fetchNextNotifications = () => {
    if (!state.nextNotifications.length)
      db.collection('NOTIFICATIONS')
        .where('start', '>', new Date())
        .orderBy('start', 'asc')
        .get()
        .then(querySnap => {
          state.nextNotifications = querySnap.docs.map(doc => {
            const data = doc.data()
            const start = data.start.toMillis()
            const end = data.end.toMillis()
            return { ...data, id: doc.id, start, end }
          })
        })
  }

  const updateNotification = async({ id, ...notification }) => {
    const start = new Date(notification.start)
    const end = new Date(notification.end)
    await db.doc(`NOTIFICATIONS/${id}`).set({ ...notification, start, end })
    resetStates()
  }

  const addNotification = async notification => {
    const start = new Date(notification.start)
    const end = new Date(notification.end)
    await db.collection(`NOTIFICATIONS`).add({ ...notification, start, end })
    resetStates()
  }

  const deleteNotification = async({ id }) => {
    await db.doc(`NOTIFICATIONS/${id}`).delete()
    resetStates()
  }

  return {
    ...toRefs(readonly(state)),
    fetchCurrentNotifications,
    fetchPreviousNotifications,
    fetchNextNotifications,
    updateNotification,
    addNotification,
    deleteNotification
  }
}
