<template>
  <app-selector all-apps />
  <div class="notifications-view">
    <paginated-panel :items="displayedNotifications">
      <template #header>
        <div class="pagination-header">
          <selector-switch v-model="dateFilter" :values="possibleDateFilters" />
          <button class="main-button second-accent" @click="openEditor()"> <v-svg h="1.4em" name="new-notification" /> Nouvelle notification </button>
        </div>
      </template>
      <template #content="slotProps">
        <notification :notification="slotProps.item" @edit="openEditor(slotProps.item)" @delete="deleteNotification(slotProps.item)" />
      </template>
    </paginated-panel>
    <notification-editor v-if="editorOpened"
                         :notif="editionItem"
                         @delete="deleteNotification"
                         @save="saveNotification"
                         @close="closeEditor" />
  </div>
</template>

<script>
  import AppSelector from '@/components/AppSelector'
  import PaginatedPanel from '@/components/PaginatedPanel'
  import Notification from '@/components/notification/Notification'
  import NotificationEditor from '@/components/notification/NotificationEditor'

  import { readonly, ref, computed, watch } from 'vue'
  import useNotificationStore from '@/store/notification.store'
  import useApplicationStore from '@/store/application.store'
  import useModalEditor from '@/mixins/modalEditor'

  const DateFilters = [
    { id: 'current', label: 'En cours' },
    { id: 'next', label: 'À venir' },
    { id: 'previous', label: 'Passé' }
  ]

  export default {
    name: 'Notifications',
    components: { AppSelector, PaginatedPanel, Notification, NotificationEditor },
    setup() {
      const notificationStore = useNotificationStore()
      const applicationStore = useApplicationStore()
      const modalEditor = useModalEditor()

      const possibleDateFilters = readonly(DateFilters)
      const dateFilter = ref('current')

      const { selectedAppId } = applicationStore
      const displayedNotifications = computed(() =>
        notificationStore[`${dateFilter.value}Notifications`].value.filter(n => !selectedAppId.value || n.apps.includes(selectedAppId.value))
      )

      const fetchNotifications = () => {
        const upper = dateFilter.value[0].toUpperCase() + dateFilter.value.slice(1)
        notificationStore[`fetch${upper}Notifications`]()
      }

      watch(dateFilter, () => fetchNotifications(), { immediate: true })

      const saveNotification = async notification => {
        if (modalEditor.editionItem.value) await notificationStore.updateNotification(notification)
        else await notificationStore.addNotification(notification)
        fetchNotifications()
        modalEditor.closeEditor(true)
      }

      const deleteNotification = async notification => {
        if (confirm(`Supprimer la notification ${notification.title} ?`)) await notificationStore.deleteNotification(notification)
        fetchNotifications()
        modalEditor.closeEditor(true)
      }

      return {
        possibleDateFilters,
        dateFilter,
        displayedNotifications,
        saveNotification,
        deleteNotification,
        ...modalEditor
      }
    }
  }
</script>

<style lang="scss" scoped>
  .notifications-view {
    padding: 2rem;
    .pagination-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }
</style>
