<template>
  <div class="notification">
    <div class="apps">
      <template v-if="!showMoreApps">
        <app-logo v-for="app in notification.apps" :key="`logo${app}`" class="bg-grey" :application="app" />
      </template>
      <template v-else>
        <app-logo v-for="app in notification.apps.slice(0, 2)" :key="`logo${app}`" class="bg-grey" :application="app" />
        <div v-tooltip.bottom="moreApps" class="more-apps">+{{ notification.apps.length - 2 }}</div>
      </template>
    </div>
    <div class="content">
      <div class="head">
        <div class="title">{{ notification.title }}</div>
        <div class="dates">{{ dates }}</div>
      </div>
      <div class="description">{{ notification.text }}</div>
      <div class="actions">
        <button class="inline-button main-accent" @click="$emit('edit')"><v-svg h="1.4em" name="edit" /> Modifier</button>
        <button class="inline-button destructive" @click="$emit('delete')"> <v-svg h="1.4em" name="delete" /> Supprimer</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import useApplicationStore from '@/store/application.store'
  import { DateTime } from 'luxon'

  export default {
    name: 'Notification',
    props: {
      notification: {
        type: Object,
        required: true
      }
    },
    emits: ['edit', 'delete'],
    setup(props) {
      const applicationStore = useApplicationStore()
      const showMoreApps = computed(() => props.notification.apps.length > 3)

      const moreApps = computed(() =>
        props.notification.apps
          .slice(2)
          .map(app => applicationStore.applications.value.find(a => a.id === app.id)?.name)
          .join(', ')
      )

      const dates = computed(() => {
        const toFormat = millis => DateTime.fromMillis(millis).toFormat(`dd/MM/yyyy HH'h'mm`)
        return `du ${toFormat(props.notification.start)} au ${toFormat(props.notification.end)}`
      })

      return {
        showMoreApps,
        moreApps,
        dates
      }
    }
  }
</script>

<style lang="scss" scoped>
  .notification {
    display: grid;
    grid-template-columns: 2rem 1fr;
    gap: 1rem;
    min-height: 7.6rem;
    margin: 0.5rem 0;
    .apps {
      .app-logo:not(:last-child) {
        margin-bottom: 0.3rem;
      }
      .more-apps {
        @extend %font-title-6;
        color: var(--color-second-accent);
        height: 2rem;
        width: 2rem;
        border-radius: 0.25rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: hsla(var(--hsla-black), 0.05);
      }
    }
    .content {
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .title {
          @extend %font-title-5;
        }
        .dates {
          color: hsla(var(--hsla-black), 0.5);
        }
      }
      .description {
        color: hsla(var(--hsla-black), 0.7);
      }
      .actions {
        display: inline-flex;
        align-items: center;
        padding: 8px 0 16px 0;
        border-bottom: 1px solid hsla(var(--hsla-black), 0.1);
        .inline-button {
          padding-left: 0;
        }
      }
    }
  }
</style>
