<template>
  <side-popup @close="$emit('close')">
    <div class="head">
      <input v-model="title" placeholder="Titre de la notification" type="text" class="input-title" />
      <button class="inline-button destructive" @click="$emit('close')"><v-svg name="close" />Fermer</button>
    </div>
    <div class="content">
      <div class="label">Applications</div>
      <div class="app-list">
        <div v-for="app in apps" :key="app.id" class="app">
          <app-logo class="bg-grey" :application="app" />
          <div class="remove-app" @click="removeApp(app)"><v-svg h="14px" name="close"/></div>
        </div>
        <select v-model="appToAdd" class="button">
          <option :value="null" hidden>Ajouter</option>
          <option v-for="a in appsNotSelected" :key="a.id" :value="a">{{ a.name }}</option>
        </select>
      </div>
      <div class="label">Contenu</div>
      <textarea v-model="text" class="textarea" cols="30" rows="5" />
      <div class="label">Début</div>
      <input v-model="start" class="input-datetime" type="datetime-local" />
      <div class="label">Fin</div>
      <input v-model="end" class="input-datetime" type="datetime-local" />
    </div>
    <template #footer>
      <div class="footer">
        <div class="delete" @click="deleteNotification"><v-svg name="delete"/></div>
        <div class="save" @click="saveNotification"><v-svg name="save" />Enregistrer </div>
      </div>
    </template>
  </side-popup>
</template>

<script>
  import { computed, ref, watch } from 'vue'
  import useApplicationStore from '@/store/application.store'
  import { DateTime } from 'luxon'

  export default {
    name: 'NotificationEditor',
    props: {
      notif: {
        type: Object,
        default: null
      }
    },
    emits: ['close', 'save', 'delete'],
    setup(props, context) {
      const applicationStore = useApplicationStore()
      const { applications, getAppById } = applicationStore

      const millisToISO = millis => (millis ? DateTime.fromMillis(millis).toFormat(`yyyy-MM-dd'T'HH:mm`) : null)

      const apps = ref(props.notif?.apps?.map(a => getAppById(a)) ?? [])
      const start = ref(millisToISO(props.notif?.start ?? DateTime.now().toMillis()))
      const end = ref(
        millisToISO(
          props.notif?.end ??
            DateTime.now()
              .plus({ day: 1 })
              .toMillis()
        )
      )
      const title = ref(props.notif?.title ?? '')
      const text = ref(props.notif?.text ?? '')

      //APPS
      const appToAdd = ref(null)
      const appsNotSelected = computed(() => applications.value.filter(a => !apps.value.map(app => app.id).includes(a.id)))

      watch(appToAdd, () => {
        if (appToAdd.value) {
          apps.value.push(appToAdd.value)
          appToAdd.value = null
        }
      })

      const removeApp = app => {
        apps.value = apps.value.filter(a => a.id !== app.id)
      }

      const appIds = computed(() => apps.value.map(a => a.id))

      //NOTIF
      const notification = computed(() => ({
        apps: appIds.value,
        start: DateTime.fromISO(start.value).toMillis(),
        end: DateTime.fromISO(end.value).toMillis(),
        title: title.value,
        text: text.value,
        id: props.notif?.id ?? undefined
      }))

      const saveNotification = () => {
        if (!!apps.value.length && !!title.value && !!text.value && !!start.value && !!end.value) {
          context.emit('save', notification.value)
        }
      }

      const deleteNotification = () => context.emit('delete', notification.value)

      return {
        title,
        apps,
        text,
        start,
        end,
        appsNotSelected,
        appToAdd,
        removeApp,
        saveNotification,
        deleteNotification
      }
    }
  }
</script>

<style lang="scss" scoped>
  .label {
    color: hsla(var(--hsla-black), 0.5);
  }
  .head {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 24px;
    .input-title {
      @extend %font-title-3;
      height: 51px;
      border: 1px solid hsla(var(--hsla-black), 0.1);
      border-radius: 8px;
      width: 100%;
      outline: none;
      padding: 16px;
      &:active,
      &:focus {
        border: 1px solid var(--color-second-accent);
      }
    }
  }
  .content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 24px;
    .app-list {
      display: flex;
      .app {
        position: relative;
        margin-right: 8px;
        .remove-app {
          color: var(--color-white);
          background: var(--color-destructive);
          border-radius: 50%;
          position: absolute;
          right: -4px;
          top: -4px;
          cursor: pointer;
        }
      }
      .button {
        @extend %font-body;
        border: none;
        outline: none;
        padding: 4px 6px;
        border-radius: 8px;
        border: none;
        outline: none;
        background: hsla(var(--hsla-black), 0.05);
        cursor: pointer;
        transition: all 200ms;
        display: flex;
        align-items: center;
        color: var(--color-second-accent);
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 32px;
        width: 60px;
      }
    }
    .textarea {
      @extend %font-caption-2;
      outline: none;
      border: 1px solid hsla(var(--hsla-black), 0.1);
      border-radius: 8px;
      padding: 12px;
      resize: none;
      &:active,
      &:focus {
        border: 1px solid var(--color-second-accent);
      }
    }
    .input-datetime {
      @extend %font-body;
      outline: none;
      border: 1px solid hsla(var(--hsla-black), 0.1);
      border-radius: 8px;
      padding: 4px;
    }
  }
  .footer {
    display: grid;
    grid-template-columns: 44px 1fr;
    justify-items: center;
    height: 44px;
    align-items: center;
    color: var(--color-white);
    .delete,
    .save {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background 200ms;
    }
    .delete {
      cursor: pointer;
      background: var(--color-destructive);
      &:hover {
        background: var(--color-destructive-darker);
      }
    }
    .save {
      cursor: pointer;
      background: var(--color-second-accent);
      &:hover {
        background: var(--color-second-accent-darker);
      }
    }
  }
</style>
